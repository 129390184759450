<template>
  <b-card>
    <b-row v-show="phases.length > 0 || search != null">
      <b-col md="7">
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Exibir"
              label-for="h-exibir"
              label-cols-md="4"
              class="text-muted"
            >
              <b-form-select
                v-model="perPage"
                size="sm"
                class="form-content"
                :options="perPageOptions"
                :disabled="isLoading"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="3">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="search"
            placeholder="Pesquisar"
            debounce="800"
            type="search"
            size="sm"
            :disabled="isLoading"
          />
        </b-input-group>
      </b-col>
      <b-col md="2">
        <b-button
          v-b-toggle.sidebar-create
          block
          variant="relief-primary"
          size="sm"
          @click="addNew"
        >
          Nova Fase
          <feather-icon icon="BoxIcon" />
        </b-button>
      </b-col>
    </b-row>
    <div v-show="phases.length > 0 || search != null">
      <b-overlay
        :show="isLoading"
        opacity="0.70"
        variant="transparent"
        rounded="sm"
      >
        <b-table-simple
          id="tablePhases"
          striped
          responsive
          class="mt-2"
        >
          <b-thead>
            <b-tr class="text-center">
              <b-th>Nome</b-th>
              <b-th>Cor</b-th>
              <b-th>Data de cadastro</b-th>
              <b-th>Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(tr, indextr) in phases"
              :key="indextr"
            >
              <b-td>
                <b-avatar
                  :src="tr.icon"
                />
                {{ tr.name }}
              </b-td>
              <b-td class="text-center">
                <input 
                  id="head" 
                  type="color" 
                  name="head" 
                  :value="tr.color"
                  disabled
                >
              </b-td>
              <b-td class="text-center">
                {{ tr.created_at | dateFormat }}
              </b-td>
              <b-td class="text-center">
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  v-b-toggle.sidebar-update-phase
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  @click="updateDetail(tr.id)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  v-b-modal.modal-delete
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  @click="showDetail(tr.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div
          v-show="totalRows > 10"
          class="row"
        >
          <div class="col-6 col-md-9"> 
            Exibindo {{ perPage }} de {{ totalRows }} registros
          </div>
          <div class="col-6 col-md">
            <b-pagination
              v-model="currentPage"
              class="text-center float-right"
              :per-page="perPage"
              :total-rows="totalRows"
              aria-controls="tableSchools"
              size="sm"
              @change="handlePageChange"
            />
          </div>
        </div>
      </b-overlay>
    </div>
    <div
      v-show="phases.length == 0 && search == null"
      class="text-center"
    >
      <b-overlay
        :show="isLoading"
        opacity="0.70"
        variant="transparent"
        rounded="sm"
      >
        <no-data />
        <b-button
          v-b-toggle.sidebar-create
          variant="relief-primary"
          size="sm"
          @click="addNew"
        >
          Nova Fase
          <feather-icon icon="BoxIcon" />
        </b-button>
      </b-overlay>
    </div>
    <create-edit
      :key="count"
      @loadingTable="showDetail"
    />
    <!-- Modal Delete -->
    <b-modal
      v-if="phasesModel != null"
      id="modal-delete"
      ref="modal-delete"  
      ok-variant="danger"
      modal-class="modal-danger"
      centered
      title="Deletar"
    >
      <b-card-text>
        <p>Você está preste a deletar esta fase do sistema: <b>{{ phasesModel.name }}</b></p>
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox
          id="checkbox-1"
          v-model="DeleteStatus"
          name="checkbox-1"
          value="accepted"
          @change="newDeleteStatus"
        >
          Concordo em excluir esta fase
        </b-form-checkbox>  
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="DeleteStatus == false"
            @click="deletePhase(phasesModel.id)"
          >
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>
    <!-- Sidebar Update -->
    <b-sidebar
      id="sidebar-update-phase"
      ref="sidebar-update-phase"
      shadow
      right
      bg-variant="white"
      backdrop
      title="Editar fase"
    >
      <div>
        <hr>
        <b-form
          enctype="multipart/form-data"
          method="POST"
        >
          <validation-observer ref="simpleRules">
            <b-form-group
              label="Titulo:"
              label-for="i-nome"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="titulo"
                rules="required"
              >
                <b-form-input
                  id="i-nome"
                  v-model="updatePhases.name"
                  placeholder="Ex: Imaginar"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-input
              class="hidden"
              name="_token"
              :value="csrf"
            />
            <b-form-group
              label="Icone:"
              label-for="i-icone"
              class="pl-1 pr-1"
            >
              <b-form-file
                v-model="iconUpdate"
                placeholder="Informe o Icone"
                label="Icone"
                type="file"
                accept="image/*"
                name="icon"
              />
            </b-form-group>
            <b-form-group
              label="Cor:"
              label-for="i-cor"
              class="pl-1 pr-1"
            >
              <input 
                id="head" 
                v-model="updatePhases.color" 
                type="color" 
                name="head"
                class="form-control"
              >
            </b-form-group>
            <b-row class="p-1">
              <b-button 
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                class="ml-1 mr-1"
                @click="updatePhase()"
              >
                Atualizar
              </b-button>
            </b-row>
          </validation-observer>
        </b-form>
      </div>
    </b-sidebar>
  </b-card>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CreateEdit from "./CreateEdit.vue";
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import noData from "@/components/systemWarning/NoData.vue";

export default {
  components: {
    BCard,
    CreateEdit,
    ValidationProvider,
    ValidationObserver,
    noData
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      count: 1,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      isLoading: true,

      DeleteStatus: null,
      phasesModel: null,

      iconUpdate: null,
      updatePhases: {
        name: null,
        color: null,
        id: null,
      },

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    };
  },
  computed: {
    ...mapState("phases", ["phases"]),
  },
  methods: {
    ...mapActions("phases", ["update"]),

    addNew() {
      this.count++;
    },

    fetchPhases(paginated = true, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("phases/fetchPhases", {
          paginate: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    handlePageChange(value) {
      this.fetchPhases(true, value)
    },

    showDetail(id){
      this.phasesModel = this.$store.getters['phases/getPhasesById'](id)
    },

    newDeleteStatus(value){
      this.DeleteStatus = value
    },

    deletePhase(id){
      this.isLoading = true;
      let data = id
      this.$store
        .dispatch("phases/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.isLoading = false
          this.DeleteStatus = null
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.fetchPhases()
          this.$refs['modal-delete'].hide()
        })
    },

    updateDetail(id){
      this.updateModel = this.$store.getters['phases/getPhasesById'](id)
      this.updatePhases.name = this.updateModel.name
      this.updatePhases.color = this.updateModel.color
      this.updatePhases.id = this.updateModel.id
    },

    updatePhase() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.update({
            ...this.updatePhases,
            icon: this.iconUpdate,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Atualizado com sucesso!',
                icon: 'ThumbsUpIcon',
                text: 'Operação executada com sucesso.',
                variant: 'success'
              },
            })
            this.$nextTick(() => this.$refs.simpleRules.reset());
          })
          .finally(() =>{
            this.$refs['sidebar-update-phase'].hide()
            this.fetchPhases()
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
        
    }
  },
  created() {
    this.fetchPhases();
  },
  watch: {
    perPage(){
      this.fetchPhases()
    },
    search(){
      this.currentPage = 1;
      this.fetchPhases(true, 1);
    }
  },
};
</script>
<style lang="scss">
  .form-content{
    width: 5rem;

  }
  .form-content-search{
    width: 11rem;
  }
</style>